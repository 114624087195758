import * as React from 'react';
import { Caption } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getCatalogIsTimedPlusBiddingOpen,
    getCatalogIsTimedPlusClosing,
    getCatalogStartTime,
    getCatalogTimedPlusBiddingEndsFromTs,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { longFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { useAppSelector } from '@/redux/hooks';
import styled from 'styled-components';

export type CatalogCardTimedPlusDateProps = {
    catalogId: number;
};

const CatalogCardTimedPlusDate = ({ catalogId }: CatalogCardTimedPlusDateProps) => {
    const isOpen = useAppSelector((state) => getCatalogIsTimedPlusBiddingOpen(state, catalogId));
    const isClosing = useAppSelector((state) => getCatalogIsTimedPlusClosing(state, catalogId));
    const startTime = useAppSelector((state) => getCatalogStartTime(state, catalogId));
    const endsFromTime = useAppSelector((state) => getCatalogTimedPlusBiddingEndsFromTs(state, catalogId));

    const [saleStartDate, setSaleStarDate] = React.useState('');
    const [saleEndDate, setSaleEndDate] = React.useState('');

    React.useEffect(() => {
        setSaleStarDate(longFormatDate(startTime));
        setSaleEndDate(longFormatDate(endsFromTime));
    }, [startTime, endsFromTime]);

    if (isClosing) {
        return (
            <StyledDateContainer>
                <StyledSecondaryTime color="secondary">
                    <FormattedMessage id="catalogPage.catalogDate.endingNow" />
                </StyledSecondaryTime>
            </StyledDateContainer>
        );
    }
    if (isOpen) {
        return (
            <StyledDateContainer>
                <StyledSecondaryTime
                    color="secondary"
                    suppressHydrationWarning
                >
                    <FormattedMessage
                        id="catalogPage.catalogDate.endsFrom"
                        values={{
                            date: saleEndDate,
                        }}
                    />
                </StyledSecondaryTime>
            </StyledDateContainer>
        );
    }

    return (
        <StyledDateContainer>
            <StyledSecondaryTime color="secondary">
                <FormattedMessage
                    id="catalogPage.catalogDate.startsOn"
                    values={{
                        date: saleStartDate,
                    }}
                />
            </StyledSecondaryTime>
        </StyledDateContainer>
    );
};

export default CatalogCardTimedPlusDate;

const StyledDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const StyledSecondaryTime = styled(Caption)`
    display: block;
`;
