import * as React from 'react';
import CatalogVisibilityWrapper from './CatalogVisibilityWrapper';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import type { CatalogCardType, HorizontalCatalogCardType, PreviewCatalogCardType } from './Cards';

type Props = {
    CardComponent?: CatalogCardType | HorizontalCatalogCardType | PreviewCatalogCardType;
    catalogCardClass?: string;
    catalogIds: number[];
    className?: string;
    horizontal?: boolean;
    lazyLoadImages?: boolean;
    previewSellerId?: number;
    testid?: string;
    trackClick?: (id: number) => void;
};

const CatalogGrid = ({
    CardComponent,
    catalogCardClass = 'catalog-card',
    catalogIds,
    className,
    horizontal = false,
    lazyLoadImages,
    testid,
    trackClick,
}: Props) => {
    const mapCatalogCards = React.useCallback(
        (catalogId: number, index: number) => {
            const cardProps: any = {
                catalogId,
                horizontal,
                lazyLoadImages: !lazyLoadImages && index < 6 ? false : true,
                trackClick,
            };

            if (CardComponent) {
                return (
                    <CardComponent
                        className={catalogCardClass}
                        key={`${catalogId}-catalog-grid-card`}
                        {...cardProps}
                    />
                );
            }

            return (
                <StyledCatalogCardWrapper
                    className={catalogCardClass}
                    data-testid="catalogCardWrapper"
                    key={`${catalogId}-catalog-grid-card`}
                >
                    <CatalogVisibilityWrapper {...cardProps} />
                </StyledCatalogCardWrapper>
            );
        },
        [catalogCardClass, horizontal, lazyLoadImages, CardComponent, trackClick]
    );

    return (
        <StyledCatalogCardGrid
            className={className}
            data-testid={testid}
        >
            {catalogIds.map(mapCatalogCards)}
        </StyledCatalogCardGrid>
    );
};

export default React.memo<Props>(CatalogGrid, isEqual);

const StyledCatalogCardGrid = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    padding: 0;
`;

const StyledCatalogCardWrapper = styled.div`
    flex: 1 1 auto;
    max-width: 100%;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0 10px 16px;
    transition: all 0.5s;

    @media (min-width: 600px) {
        max-width: calc(100% / 2);
        width: calc(100% / 2);
    }

    @media (min-width: 986px) {
        max-width: calc(100% / 3);
        width: calc(100% / 3);
    }
`;
