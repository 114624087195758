import * as React from 'react';
import { Caption } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getCatalogEndTime,
    getCatalogIsCatalogOnly,
    getCatalogIsDone,
    getCatalogIsTimedPlus,
    getCatalogStartTime,
    getCatalogStatus,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { longFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { shouldShowLive } from '@/utils/live';
import { useAppSelector } from '@/redux/hooks';
import CatalogCardTimedPlusDate from '@/components/CatalogCard/components/CatalogCardDate/TimedPlusDate/CatalogCardTimedPlusDate';
import styled from 'styled-components';

type Props = {
    catalogId: number;
    className?: string;
    forceIsLive?: boolean;
};

const CatalogDate = ({ catalogId, className, forceIsLive }: Props) => {
    const isCatalogDone = useAppSelector((state) => getCatalogIsDone(state, catalogId));
    const isCatalogTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));

    const saleStartTs = useAppSelector((state) => getCatalogStartTime(state, catalogId)) || Date.now() / 1000;
    const saleEndTs = useAppSelector((state) => getCatalogEndTime(state, catalogId)) || Date.now() / 1000;
    const catalogStatus = useAppSelector((state) => getCatalogStatus(state, catalogId));
    const isCatalogOnly = useAppSelector((state) => getCatalogIsCatalogOnly(state, catalogId));
    const isLive = forceIsLive || shouldShowLive(catalogStatus, isCatalogOnly, saleStartTs);

    const [saleStartDate, setSaleStarDate] = React.useState('');
    const [saleEndDate, setSaleEndDate] = React.useState('');

    React.useEffect(() => {
        setSaleStarDate(longFormatDate(saleStartTs));
        setSaleEndDate(longFormatDate(saleEndTs));
    }, [saleStartTs, saleEndTs]);

    return (
        <StyledCatalogDate
            className={className}
            data-testid="catalog-date"
        >
            {isCatalogDone ? (
                <Caption color="secondary">{saleEndDate}</Caption>
            ) : isCatalogTimedPlus ? (
                <CatalogCardTimedPlusDate catalogId={catalogId} />
            ) : isLive ? (
                <StyledSecondaryTime
                    color="secondary"
                    data-testid="timed-or-live-date"
                    suppressHydrationWarning
                >
                    <FormattedMessage
                        id="catalogPage.catalogDate.started"
                        values={{
                            date: saleStartDate,
                        }}
                    />
                </StyledSecondaryTime>
            ) : (
                <StyledSecondaryTime
                    color="secondary"
                    data-testid="timed-or-live-date"
                    suppressHydrationWarning
                >
                    <FormattedMessage
                        id="catalogPage.catalogDate.startsOn"
                        values={{
                            date: saleStartDate,
                        }}
                    />
                </StyledSecondaryTime>
            )}
        </StyledCatalogDate>
    );
};

export default CatalogDate;

const StyledCatalogDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px 0 0;
`;

const StyledSecondaryTime = styled(Caption)`
    display: block;
`;
