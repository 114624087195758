import * as React from 'react';
import { getCatalogCoverLotsTransformed } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCatalogUrl } from '@/utils/urls';
import { useAppSelector } from '@/redux/hooks';
import isEqual from 'lodash/isEqual';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled, { css } from 'styled-components';

type Props = {
    catalogId: number;
    className?: string;
    featured?: boolean;
    isSEOExcluded: boolean;
    lazyLoadImages?: boolean;
    title: string;
};

// these are NOT temporary - :(
const customImages = {
    146331: 'https://images.liveauctioneers.com/static/mail/images/featured/20190724_handbags.gif',
    146504: 'https://images.liveauctioneers.com/static/mail/images/featured/20190724_stair.gif',
};

const CoverLotsComponent = ({
    catalogId,
    className = '',
    featured = false,
    isSEOExcluded = false,
    lazyLoadImages,
    title,
}: Props) => {
    const {
        primaryImage,
        primaryImageHighDpi,
        primaryItemTitle,
        secondaryImage,
        secondaryImageHighDpi,
        secondaryItemTitle,
        secondaryItemUrl,
        tertiaryImage,
        tertiaryImageHighDpi,
        tertiaryItemTitle,
        tertiaryItemUrl,
    } = useAppSelector((state) => getCatalogCoverLotsTransformed(state, catalogId), isEqual);

    const catalogUrl = getCatalogUrl(catalogId, title);

    const single = !secondaryItemUrl && !tertiaryItemUrl;
    const double = secondaryItemUrl && !tertiaryItemUrl;
    const linkRel = isSEOExcluded ? 'nofollow' : '';

    return (
        <StyledCoverLots
            $featured={featured}
            className={className}
        >
            <StyledImageOuterWrapper>
                <StyledPrimaryImageContainer
                    $double={double}
                    $single={single}
                    rel={linkRel}
                    route
                    to={catalogUrl}
                >
                    <StyledPrimaryImage
                        alt={primaryItemTitle}
                        loading={lazyLoadImages ? 'lazy' : 'eager'}
                        src={customImages[catalogId] || primaryImage}
                        srcSet={customImages[catalogId] || `${primaryImageHighDpi} 2x`}
                    />
                </StyledPrimaryImageContainer>
                <StyledSecondaryImageWrapper
                    $double={double}
                    $single={single}
                >
                    {secondaryItemUrl && (
                        <StyledSecondaryImageContainer
                            rel={linkRel}
                            route
                            to={catalogUrl}
                        >
                            <StyledSecondaryImage
                                alt={secondaryItemTitle}
                                loading={lazyLoadImages ? 'lazy' : 'eager'}
                                src={secondaryImage}
                                srcSet={`${secondaryImageHighDpi} 2x`}
                            />
                        </StyledSecondaryImageContainer>
                    )}
                    {tertiaryItemUrl && (
                        <StyledSecondaryImageContainer
                            rel={linkRel}
                            route
                            to={catalogUrl}
                        >
                            <StyledSecondaryImage
                                alt={tertiaryItemTitle}
                                loading={lazyLoadImages ? 'lazy' : 'eager'}
                                src={tertiaryImage}
                                srcSet={`${tertiaryImageHighDpi} 2x`}
                            />
                        </StyledSecondaryImageContainer>
                    )}
                </StyledSecondaryImageWrapper>
            </StyledImageOuterWrapper>
        </StyledCoverLots>
    );
};

export default CoverLotsComponent;

const StyledCoverLots = styled.div<{ $featured: boolean }>`
    width: 100%;

    /* If not featured, all the images are square, otherwise they are rectangular */
    padding-bottom: ${({ $featured }) => ($featured ? '42.85714286%' : '66.33333333333%')};
    position: relative;
    border: solid 1px ${({ theme }) => theme.colors.grey400};

    &.featured {
        padding-bottom: 42.85714286%;
    }
`;

const StyledImageOuterWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const StyledPrimaryImageContainer = styled(Link)<{
    $double: boolean;
    $single: boolean;
}>`
    float: left;
    text-align: center;
    width: calc((100% / 3) * 2);
    height: 100%;
    display: block;
    border-right: 1px solid ${({ theme }) => theme.colors.grey400};
    position: relative;
    overflow: hidden;
    ${({ $double, $single }) => {
        if ($double) {
            return css`
                width: 50%;
            `;
        } else if ($single) {
            return css`
                width: 100%;
            `;
        }
    }}
`;

const StyledPrimaryImage = styled.img`
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-height: 101%;
    max-width: 101%;
    position: absolute;
    right: 0;
    top: 0;
`;

const StyledSecondaryImageWrapper = styled.div<{
    $double: boolean;
    $single: boolean;
}>`
    width: calc(100% / 3);
    height: 100%;
    display: inline-block;
    ${({ $double, $single }) => {
        if ($single) {
            return css`
                display: none;
            `;
        } else if ($double) {
            return css`
                width: 50%;
                && {
                    a {
                        height: 100%;
                        border-bottom: none;
                    }
                }
            `;
        }
    }}
`;

const StyledSecondaryImageContainer = styled(Link)`
    overflow: hidden;
    height: 50%;
    width: 100%;
    display: block;
    position: relative;

    &:first-child {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    }
`;

const StyledSecondaryImage = styled.img`
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-height: 101%;
    max-width: 101%;
    position: absolute;
    right: 0;
    top: 0;
`;
