import * as React from 'react';
import { getCatalogPercentComplete } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getLiveCatalogCurrentItemIndex } from '@/redux/modules/console';
import { useAppSelector } from '@/redux/hooks';
import styled from 'styled-components';

type Props = {
    catalogId: number;
    className?: string;
};

const CatalogCardProgressBar = ({ catalogId, className }: Props) => {
    const currentItemIndex = useAppSelector((state) => getLiveCatalogCurrentItemIndex(state, catalogId));
    const percent = useAppSelector((state) => getCatalogPercentComplete(state, catalogId, currentItemIndex));
    return (
        <StyledCatalogCardProgressBar className={className}>
            <CatalogCardProgressBarInner $percentComplete={Math.max(2.5, percent)} />
        </StyledCatalogCardProgressBar>
    );
};

export default CatalogCardProgressBar;

const StyledCatalogCardProgressBar = styled.div`
    height: 4px;
    background-color: ${({ theme }) => theme.colors.grey400};
`;

const CatalogCardProgressBarInner = styled.div<{ $percentComplete }>`
    width: ${({ $percentComplete }) => $percentComplete}%;
    background-color: ${({ theme }) => theme.colors.red200};
    transition: all 0.2s ease-in;
    height: 4px;
`;
