import * as React from 'react';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { Caption, H4 } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getCatalogAbsenteeBidders,
    getCatalogCountry,
    getCatalogHasVideo,
    getCatalogIsDone,
    getCatalogIsSEOExcluded,
    getCatalogIsTimedPlus,
    getCatalogIsTimedPlusClosing,
    getCatalogSellerId,
    getCatalogSellerName,
    getCatalogShortAddress,
    getCatalogStartTime,
    getCatalogTitle,
    getShouldShowLive,
} from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCatalogUrl, getSellerUrl } from '@/utils/urls';
import { getLiveCatalogBidders, getLiveCatalogStatus } from '@/redux/modules/console';
import { Link, SubtleMonochromeLink } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import BidNowLink from './components/BidNowLink/BidNowLink';
import CatalogCardDate from './components/CatalogCardDate/CatalogCardDate';
import CatalogCardProgressBar from './CatalogCardProgressBar';
import CatalogDate from '../CatalogDate/CatalogDate';
import ConsoleSocialBadge from '../ConsoleSocial/ConsoleSocialBadge';
import CoverLotsComponent from '@/components/CoverLots/CoverLotsComponent';
import Flag from '@liveauctioneers/caterwaul-components/lib/Intl/Flag';
import styled, { css } from 'styled-components';
import TimedPlusBadge from '@liveauctioneers/caterwaul-components/lib/Badges/TimedPlusBadge';

export type CatalogCardProps = {
    catalogId: number;
    className?: string;
    featured?: boolean;
    lazyLoadImages?: boolean;
    trackClick: (event: React.SyntheticEvent) => void;
};

const CatalogCard = ({
    catalogId,
    className = 'catalog-card',
    featured,
    lazyLoadImages,
    trackClick,
}: CatalogCardProps) => {
    // Standard catalog data selectors
    const isSEOExcluded = useAppSelector((state) => getCatalogIsSEOExcluded(state, catalogId));
    const sellerId = useAppSelector((state) => getCatalogSellerId(state, catalogId));
    const name = useAppSelector((state) => getCatalogSellerName(state, catalogId));
    const title = useAppSelector((state) => getCatalogTitle(state, catalogId));
    const absenteeBidders = useAppSelector((state) => getCatalogAbsenteeBidders(state, catalogId));
    const saleStartTs = useAppSelector((state) => getCatalogStartTime(state, catalogId));
    const hasVideo = useAppSelector((state) => getCatalogHasVideo(state, catalogId));
    const isDone = useAppSelector((state) => getCatalogIsDone(state, catalogId));
    const showLive = useAppSelector((state) => getShouldShowLive(state, catalogId));
    const isTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));
    const isTimedPlusClosing = useAppSelector((state) => getCatalogIsTimedPlusClosing(state, catalogId));
    const countryCode = useAppSelector((state) => getCatalogCountry(state, catalogId));
    const location = useAppSelector((state) => getCatalogShortAddress(state, catalogId));

    // Live catalog data selectors
    const liveStatus = useAppSelector((state) => getLiveCatalogStatus(state, catalogId));
    const bidders = useAppSelector((state) => getLiveCatalogBidders(state, catalogId));

    const { formatMessage } = useIntl();
    const live = showLive || liveStatus === 'live';
    const viewers = bidders + absenteeBidders;

    const catalogUrl = getCatalogUrl(catalogId, title);
    const rel = isSEOExcluded ? 'nofollow' : '';

    return (
        <StyledCatalogCard
            className={className}
            onClick={trackClick}
            suppressHydrationWarning
        >
            <StyledCoverLots
                catalogId={catalogId}
                featured={featured}
                isSEOExcluded={isSEOExcluded}
                lazyLoadImages={lazyLoadImages}
                title={title}
            />
            {isTimedPlus && !isDone && isTimedPlusClosing ? (
                <StyledTimedPlusBadge closing={isTimedPlusClosing} />
            ) : live && !isTimedPlus ? (
                <StyledConsoleSocialBadge
                    hasVideo={hasVideo}
                    viewers={viewers}
                />
            ) : null}
            <StyledCardBody>
                {((live && !isTimedPlus) || (isTimedPlusClosing && isTimedPlus)) && (
                    <StyledCatalogCardProgressBar catalogId={catalogId} />
                )}
                <StyledTitleRow $featured={featured}>
                    <SubtleMonochromeLink
                        data-testid="featuredAuctionTitle"
                        href={catalogUrl}
                        rel={rel}
                    >
                        {title}
                    </SubtleMonochromeLink>
                </StyledTitleRow>
                <StyledSellerNameRow $featured={featured}>
                    <SubtleMonochromeLink
                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                        href={getSellerUrl(sellerId, name)}
                        size="xs"
                    >
                        {name}
                    </SubtleMonochromeLink>
                </StyledSellerNameRow>
                <StyledStartTimeRow
                    $featured={featured}
                    color="secondary"
                    suppressHydrationWarning
                >
                    <CatalogCardDate catalogId={catalogId} />
                    {featured && (
                        <Link href={catalogUrl}>
                            <StyledExploreButton data-testid="exploreButton">
                                {formatMessage({ id: 'explore' })}
                            </StyledExploreButton>
                        </Link>
                    )}
                </StyledStartTimeRow>
                {!featured && (
                    <StyledBottomRow>
                        <StyledLocationSection>
                            {countryCode && <Flag countryCode={countryCode} />}
                            <StyledLocation color="secondary">{location}</StyledLocation>
                        </StyledLocationSection>
                        {live ? (
                            <BidNowLink catalogId={catalogId} />
                        ) : (
                            <>
                                {!isTimedPlus && (
                                    <CatalogDate
                                        date={saleStartTs}
                                        onlyShowCountdown
                                    />
                                )}
                                {isDone && (
                                    <Caption color="secondary">
                                        <FormattedMessage id="auction_ended" />
                                    </Caption>
                                )}
                            </>
                        )}
                    </StyledBottomRow>
                )}
            </StyledCardBody>
        </StyledCatalogCard>
    );
};

export default React.memo<CatalogCardProps>(CatalogCard);

const StyledCatalogCard = styled.div`
    display: block;
    position: relative;
`;

const StyledCoverLots = styled(CoverLotsComponent)`
    display: block;
`;

const StyledCardBody = styled.div`
    position: relative;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    border-top: none;
    display: flex;
    flex-direction: column;
`;

const StyledCatalogCardProgressBar = styled(CatalogCardProgressBar)`
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    z-index: 2;
`;

const StyledTitleRow = styled(H4)<{ $featured: boolean }>`
    height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    ${({ $featured }) =>
        $featured
            ? css`
                  max-width: calc(100% - 130px);
                  @media (max-width: 881px) {
                      max-width: 100%;
                  }
              `
            : css`
                  max-width: 100%;
              `};
`;

const StyledSellerNameRow = styled.div<{ $featured: boolean }>`
    height: 24px;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${({ $featured }) =>
        $featured
            ? css`
                  max-width: calc(100% - 130px);
                  @media (max-width: 881px) {
                      max-width: 100%;
                  }
              `
            : css`
                  max-width: 100%;
              `};
`;

const StyledStartTimeRow = styled(Caption)<{ $featured: boolean }>`
    height: 24px;
    display: flex;
    justify-content: ${({ $featured }) => ($featured ? 'space-between' : 'flex-start')};
    align-items: center;
`;

const StyledBottomRow = styled.div`
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLocationSection = styled.div`
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* This one is because the flag has a slight left side spacing of 2px */
    margin-left: -2px;
    max-width: calc(100% - 64px);
`;

const StyledLocation = styled(Caption)`
    margin-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 50px);
`;

const StyledExploreButton = styled(Button)`
    && {
        display: inline-flex;
        position: absolute;
        right: 10px;
        top: 10px;
        @media (max-width: 881px) {
            display: none;
        }
    }
`;

const StyledConsoleSocialBadge = styled(ConsoleSocialBadge)`
    position: absolute;
    top: 8px;
    left: 8px;
`;

const StyledTimedPlusBadge = styled(TimedPlusBadge)`
    position: absolute;
    top: 8px;
    left: 8px;
`;
