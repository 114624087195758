import * as React from 'react';
import { Caption } from '@liveauctioneers/hammer-ui-core/text';
import { CaretRight } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getCatalogIsTimedPlus, getCatalogTitle } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getCatalogUrl } from '@/utils/urls';
import { InlineButton } from '@liveauctioneers/hammer-ui-core/inlineButton';
import { SubtleLink } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import BidConsoleOpener from '@/components/BidConsoleOpener/BidConsoleOpener';

type Props = {
    catalogId: number;
    className?: string;
    testid?: string;
};

const BidNowLink = ({ catalogId, className = '', testid = 'bid-now-link' }: Props) => {
    const isTimedPlus = useAppSelector((state) => getCatalogIsTimedPlus(state, catalogId));
    const title = useAppSelector((state) => getCatalogTitle(state, catalogId));

    if (isTimedPlus) {
        return (
            <SubtleLink
                data-testid={`timed-plus-catalog-link-${testid}`}
                href={getCatalogUrl(catalogId, title)}
                target="_blank"
            >
                <InlineButton
                    className="text-xs"
                    icon={CaretRight}
                    iconPlacement="right"
                    iconStyle="fill"
                    size="sm"
                    style="critical"
                    tabIndex={-1}
                >
                    <FormattedMessage id="live_auction" />
                </InlineButton>
            </SubtleLink>
        );
    }

    return (
        <BidConsoleOpener catalogId={catalogId}>
            <Caption
                className={className}
                color="critical"
                data-testid={testid}
            >
                <InlineButton
                    className="text-xs"
                    icon={CaretRight}
                    iconPlacement="right"
                    iconStyle="fill"
                    size="sm"
                    style="critical"
                >
                    <FormattedMessage id="live_auction" />
                </InlineButton>
            </Caption>
        </BidConsoleOpener>
    );
};

export default React.memo<Props>(BidNowLink);
